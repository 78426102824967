import React, { useState } from "react";

import { Text } from "theme-ui";
import { DefaultButton } from "office-ui-fabric-react";
import { useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { selectorGlobalConfig } from "gatsby-plugin-hfn-profile/state/selectors";

function BuildIndex() {
  const [state, setState] = useState({ s: "" });

  const firebase = useFirebase();

  const { enabledFireStore } = useSelector(selectorGlobalConfig);

  const refresh = () => {
    const shopventory = firebase
      .functions()
      .httpsCallable("preceptorSpecialSittings");
    setState({ loading: true, s: "" });
    shopventory({ request: "buildIndex" }).then((result) => {
      // Read result of the Cloud Function.
      setState({ loading: false, s: result.data });
    });
  };

  if (!enabledFireStore) {
    return (
      <div> FireStore Config needs to be enabled for firebase functions </div>
    );
  }

  return (
    <div>
      <Text variant="title">Build Index</Text>
      <div>
        <DefaultButton onClick={refresh} text="Refresh" />
      </div>
      <br />
      <br />
      {state.loading && "loading..."}
      <div>{JSON.stringify(state.s)}</div>
    </div>
  );
}

export default BuildIndex;
